//clear all timeouts and intervals if exists on page load
try {
  let timeoutscount = window.setTimeout(function () { }, 0);
  while (timeoutscount < 1) {
    window.clearTimeout(timeoutscount);
    timeoutscount--;
  }
} catch (err) { }
try {
  let intervalCount = window.setInterval(function () { }, 0);
  while (intervalCount < 1) {
    window.clearInterval(intervalCount);
    intervalCount--;
  }
} catch (err) { }
function iOS() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true;
  }
  return false;
}
if (iOS()) {
  //ios donot support service workers
} else {
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.oncontrollerchange = function(event) {
  //     ////console.log("sw update", event);
  //     window.location.reload(true);
  //   };
  // }
}